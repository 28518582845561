<template>
  <div>
    <div id="iv03"></div>
  </div>
</template>

<script>
import { ROOT, REPORT_URL } from "@/api/constant";
import { commonServices } from '@/api/common-services'
import jQuery from "jquery";
import "@/assets/js/telerikReportViewer-17.0.23.118.min.js";
window.$ = jQuery;
export default {
  name: "ReportViewer",
  data() {
    return {
      msg: "Print invoice",
      PaymentId: null,
      obj: null,
      objdeltai: null,
      objdeltai2: null,
      objdeltai3: null,
      objdeltai4: null,
      json: null,
      isSelectBDC: false,
      listBDC: [],
      response: null,
      keyWord: null,
      pLang: null,
      pToken: null,
      pTimeZone: null,
      pTimeZoneOffset: null,
      pPaymentId: null,
      pIndex: 0,
      pIsGetAll: true,
      pUrl: null,
      fileName: null,
    };
  },
  mounted() {
    this.$nextTick(() => this.getParam())
  },
  watch: {
    pUrl() {
      setTimeout(() => {
        this.getInvoice()
      },1000)
    }
  },
  methods: {
    async api_RCOM01() {
      const body = {
        KeyGroup : "REPORT_CONFIG"
      }
      await commonServices.getCommon(body).then(response => {
        this.fileName = response.Data.find(x => x.KeyCode == "IV03_REPORT_FILE_NAME").KeyValue
      })
    },
    async getParam() {
      const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
      this.pLang =  localStorage.getItem('systemLanguage') || '1000000'
      this.pToken =  (userGolfData) ? userGolfData.AccessToken : ''
      this.pTimeZone =  localStorage.getItem('timeZone')
      this.pTimeZoneOffset =  localStorage.getItem('timeZoneOffset')
      this.pPaymenRetailtId = this.$route.params.id * 1
      await this.api_RCOM01()
      await this.api_RCF00()
    },
    async api_RCF00() {
      await commonServices.api_RCF00().then(response => {
        this.pUrl = `${response.Data.find(x => x.SettingCode == 'SITE_API_URL').SettingValue}/api/course`
      })
    },
    getInvoice() {
      $("#iv03").telerik_ReportViewer({
          serviceUrl: REPORT_URL,
          reportSource: {
            report: this.fileName,
            parameters: {pToken: this.pToken,pPaymentRetailId: this.pPaymenRetailtId,pUrl: this.pUrl,pLang: this.pLang,pTimeZone:this.pTimeZone,pTimeZoneOffset: this.pTimeZoneOffset, pUser: JSON.parse(localStorage.getItem('userData')).userName},
          },
          viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
          scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
          scale: 1.0,
          sendEmail: { enabled: false },
        });
    },
    refreshInvoice() {
      var reportViewer = $("#iv03").data("telerik_ReportViewer");
      reportViewer.refreshReport();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
#iv03 {
  position: absolute;
  top: 50px;
  bottom: 10px;
  right: 0;
  overflow: hidden;
  clear: both;
  width: 100%;
}
.trv-report-page{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>